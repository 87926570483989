import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const Linker = props => (
	<Link className={props.cls} to={props.to}>{props.children}</Link>
);

export default function Footer() {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					env
					author
					title
					description
				}
			}
		}`
	);

	return (
		<footer className="mt-5 text-muted text-center text-small">
			<p className="mb-0 copyright">© {(new Date()).getFullYear()} {data.site.siteMetadata.title}</p>
			<ul className="list-inline">
				<li className="list-inline-item"><Linker cls="footer-link" to="/privacy/">Privacy</Linker></li>
				<li className="list-inline-item"><Linker cls="footer-link" to="/tnc/">T&C</Linker></li>
			</ul>
		</footer>
	);
}