import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, Link, graphql } from "gatsby";
import Logo from "../components/logo.svg";

import layoutStyles from "../styles/layout.module.css";
import Footer from "./footer";

import { useAuth0 } from "@auth0/auth0-react";

const Linker = props => (<Link className={props.cls} to={props.to} activeClassName="active">{props.children}</Link> );
const DEFAULT_PIC = "https://firebasestorage.googleapis.com/v0/b/debjyotiacharjee-14406.appspot.com/o/Debjyoti-Logo.jpg?alt=media";

export default function Layout({ children, title = "Debjyoti Acharjee", pic = DEFAULT_PIC }) {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					env
					author
					title
					description
					key
				}
			}
			config {
				admin
				qa
				dev
			}
		}
	`);

	const [ip, setIP] = useState("");
	const [adminUsers, setAdminUsers] = useState([]);
	const [qaUsers, setQAUsers] = useState([]);
	const [devUsers, setDevUsers] = useState([]);

	const { user, isAuthenticated } = useAuth0();

	// Fetch IP Address
	useEffect(() => {
		const fetchIP = async () => {
			try {
				const response = await fetch(
					"https://api.ipify.org/?format=json"
				);
				if (!response.ok)
					throw new Error(`IP fetch failed: ${response.statusText}`);
				const result = await response.json();
				setIP(result.ip);
			} catch (error) {
				console.error("Error fetching IP:", error);
			}
		};
		fetchIP();
	}, []);

	// Fetch Config Data
	useEffect(() => {
		const fetchConfigData = async () => {
			try {
				const response = await fetch(
					`https://api.debjyotiacharjee.workers.dev/config`,
					{
						method: "GET",
						headers: {
							"X-API-KEY": data.site.siteMetadata.key,
							Accept: "application/json",
						},
					}
				);
				if (!response.ok)
					throw new Error(
						`Config API fetch failed: ${response.statusText}`
					);
				const resultData = await response.json();
				setAdminUsers(resultData.admin);
				setQAUsers(resultData.qa);
				setDevUsers(resultData.dev);
			} catch (error) {
				console.error("Error fetching config data:", error);
			}
		};
		fetchConfigData();
	}, [data.site.siteMetadata.key]);

	const isDevEnvironment = data.site.siteMetadata.env === "DEV";
	const isAuthorizedUser =
		isAuthenticated &&
		user?.email &&
		(adminUsers.includes(user.email) ||
			qaUsers.includes(user.email) ||
			devUsers.includes(user.email));

	/* console.log(
		"DEBUG::",
		user,
		`[DEV-Env: ${isDevEnvironment} ]`,
		`| Authorized: ${isAuthorizedUser} - `,
		` Admin:${adminUsers}`, ` QA:${qaUsers}`, ` DEV:${devUsers}`
	); */

	return (
		<div className={`container ${layoutStyles.pageDefault}`}>
			<Helmet>
				<title>{title}</title>
				<html lang="en" />
				<meta name="author" content={data.site.siteMetadata.author} />
				<link href="https://fonts.googleapis.com/css2?family=Limelight&family=Roboto:wght@100;300;400&display=swap" rel="stylesheet" />
				<link href="https://use.fontawesome.com/releases/v5.14.0/css/all.css" rel="stylesheet" />
				<link href="https://use.fontawesome.com/releases/v5.14.0/css/v4-shims.css" rel="stylesheet" />
				<meta property="og:title" content={title} />
				<meta property="og:type" content="article" />
				<meta property="og:image" content={pic} />
				<meta property="og:image:secure_url" content={pic} />
			</Helmet>
			{(!isDevEnvironment || (isDevEnvironment && isAuthorizedUser)) && (
				<>
					<div className="d-flex flex-column flex-md-row align-items-center mb-3">
						<Linker cls="my-0 mr-md-auto font-weight-normal logo" to="/">
							<img src={Logo} alt={data.site.siteMetadata.title} />
							{isDevEnvironment && ( <span className="env-flag">[DEV]</span> )}
						</Linker>
						<nav className="my-2 my-md-0 mr-md-3">
							{isAuthenticated && ( <Linker cls="p-2 text-dark page-nav" to="/profile">Profile</Linker> )}
							<Linker cls="p-2 text-dark page-nav" to="/blogs">Blogs</Linker>
							{isAuthenticated && (
								<>
									<Linker cls="p-2 text-dark page-nav" to="/interests">Interests</Linker>
									<Linker cls="p-2 text-dark page-nav" to="/memories">Memories</Linker>
									<Linker cls="p-2 text-dark page-nav" to="/logout">Logout</Linker>
								</>
							)}
							{!isAuthenticated && ( <Linker cls="p-2 text-dark page-nav" to="/login">Login</Linker> )}
						</nav>
					</div>
					{children}
					<Footer />
				</>
			)}

			{(isDevEnvironment && !isAuthorizedUser) && (
				<>
					<div className="d-flex flex-column flex-md-row align-items-center mb-3">
						<Linker cls="my-0 mr-md-auto font-weight-normal logo" to="/">
							<img src={Logo} alt={data.site.siteMetadata.title} />
							{isDevEnvironment && ( <span className="env-flag">[DEV]</span> )}
						</Linker>
						<nav className="my-2 my-md-0 mr-md-3">
							{isAuthenticated && ( <Linker cls="p-2 text-dark page-nav" to="/logout">Logout</Linker> )}
							{!isAuthenticated && ( <Linker cls="p-2 text-dark page-nav" to="/login">Login</Linker> )}
						</nav>
					</div>
					<h1 className="my-0 mr-md-auto social-links"><i>Unauthorized Access!</i></h1>
					<p className="social-links">IP: {ip}</p>
					{user?.email && <p className="social-links">User: {user.email}</p>}
				</>
			)}
		</div>
	);
}
